import {Camera, CameraResultType, Photo} from "@capacitor/camera";


export const getBlobFromCamera = async (header: string): Promise<Photo> => {
  return Camera.getPhoto({
    allowEditing: true,
    quality: 100,
    width: 200,
    height: 200,
    webUseInput: true,
    resultType: CameraResultType.Uri,
    promptLabelCancel: "Annuler",
    promptLabelPhoto: "Choisir une photo 📂",
    promptLabelPicture: "Prendre une photo 🤳",
    promptLabelHeader: header,
  })
}
